<template>
  <span>
    <span v-if="term.archived" class="badge badge-secondary badge-pill"
      >Archived</span
    >
    <span
      v-else-if="term.update || term.update_of"
      class="badge badge-warning badge-pill"
      >Unpublished Changes</span
    >
    <span v-else-if="term.live" class="badge badge-primary-light badge-pill"
      >Published</span
    >
    <span v-else class="badge badge-primary badge-pill">Unpublished</span>
  </span>
</template>

<script>
export default {
  props: {
    term: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="postcss" scoped></style>
