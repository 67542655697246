<template>
  <div class="container">
    <div
      v-if="results && results.default.urgent_notes"
      class="alert alert-primary rounded-0"
      v-html="results.default.urgent_notes"
    />
    <div class="card">
      <div class="card-header d-flex justify-content-center align-items-center">
        <div class="position-absolute" style="left: 1rem;">
          <button
            class="btn btn-sm btn-primary"
            aria-label="Back"
            data-cy="back-btn"
            @click="showSelections"
          >
            <span class>←</span>
            <span class="d-none d-sm-inline">Back</span>
          </button>
        </div>
        <div
          v-if="previewMode && results"
          class="position-absolute"
          style="right: 1rem; font-size: 1.3rem;"
        >
          <TermStatusBadge :term="results.default.term" />
        </div>
        <div class>
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item" @click="() => (activeTab = 0)">
              <a
                href="#"
                :class="{ 'nav-link': true, active: activeTab === 0 }"
              >
                {{ typeDescriptor }} Tuition
              </a>
            </li>
            <li
              v-if="results && results.qtr && results.qtr.rates"
              class="nav-item"
              @click="() => (activeTab = 1)"
            >
              <a
                href="#"
                :class="{ 'nav-link': true, active: activeTab === 1 }"
                data-cy="qtr-tab"
              >
                QTR
              </a>
            </li>
          </ul>
        </div>
      </div>

      <FinalSelections
        v-bind="{ dropdowns, selections }"
        data-cy="selections"
      />
      <template v-if="activeTab === 0 && results">
        <RatesTable
          key="0"
          :rates="results.default.rates"
          :merge-program-fees="results.default.merge_program_fees"
          :merge-all-fees="results.default.merge_all_fees"
          :units-gte="results.default.units_gte"
          :title="typeDescriptor + setTitle()"
          :term="results.default.term.term_id"
        />
      </template>

      <template v-if="activeTab === 1 && results">
        <RatesTable
          key="1"
          :rates="results.qtr.rates"
          :units-gte="results.qtr.units_gte"
          :merge-program-fees="results.qtr.merge_program_fees"
          :merge-all-fees="results.default.merge_all_fees"
          title="Employee or Employee Spouse"
          :term="results.default.term.term_id"
        />
        <RatesTable
          key="2"
          :rates="results.qtr_dependent.rates"
          :units-gte="results.qtr_dependent.units_gte"
          :merge-program-fees="results.qtr_dependent.merge_program_fees"
          :merge-all-fees="results.default.merge_all_fees"
          title="Employee Dependent"
          :term="results.default.term.term_id"
        />
      </template>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="results && results.default.notes"
        class="alert alert-secondary rounded-0"
        v-html="results.default.notes"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import RatesTable from "../components/RatesTable.vue"
import FinalSelections from "../components/FinalSelections.vue"
import TermStatusBadge from "../components/TermStatusBadge.vue"

export default {
  components: {
    RatesTable,
    FinalSelections,
    TermStatusBadge,
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  /** Load data from querystring if fresh page */
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name != "home") {
        vm.$store.dispatch("loadQuerySelections")
      }
    })
  },
  computed: {
    ...mapState([
      "dropdowns",
      "selections",
      "results",
      "typeDescriptor",
      "previewMode",
    ]),
  },
  methods: {
    showSelections() {
      this.$router.push({
        name: "home",
        query: this.$route.query,
      })
    },
    setTitle() {
      if (this.results.default.merge_all_fees == true) {
        return " Tuition"
      } else {
        return " Tuition & Fees"
      }
    },
  },
}
</script>
