<template>
  <div>
    <h4 class="mt-4 ml-3" data-cy="results-title">
      {{ title }}
    </h4>
    <div class="table-responsive" data-cy="results-table">
      <table class="table table-sm p-4 table-auto fixed-layout">
        <thead class="thead-light">
          <th>Units</th>
          <th v-if="columns.has('tuition')">
            Tuition
          </th>
          <th v-if="columns.has('tuitionAndProgramFees')">
            Tuition and Program Fees
          </th>
          <th v-if="columns.has('differentialTuition')">
            Differential Tuition
          </th>
          <th v-if="columns.has('collegeFees')">
            College Fees
          </th>
          <th v-if="columns.has('programFees')">
            Program Fees
          </th>
          <th v-if="columns.has('afatFees')">
             <a
              :href="feesLink"
              target="_blank"
              rel="noopener"
              >AFAT Fees</a
          </th>

          <th v-if="columns.has('mandatoryFees')">
            <a
              :href="feesLink"
              target="_blank"
              rel="noopener"
              >Fees </a
            >
          </th>

          <th>Total</th>
        </thead>
        <tbody>
          <tr v-for="(rate, i) in orderedRates" :key="i">
            <td data-cy="units">
              {{ rate.unitString }}
            </td>
            <td v-if="columns.has('tuition')" data-cy="tuition">
              {{ formatDollars(rate.tuition) }}
            </td>
            <td v-if="columns.has('tuitionAndProgramFees')">
              {{ formatDollars(tuitionAndProgram(rate)) }}
            </td>
            <td v-if="columns.has('differentialTuition')">
              {{ formatDollars(rate.differential_tuition) }}
            </td>
            <td v-if="columns.has('collegeFees')">
              {{ formatDollars(rate.college_fees) }}
            </td>
            <td v-if="columns.has('programFees')">
              {{ formatDollars(rate.program_fees) }}
            </td>

            <td v-if="columns.has('afatFees')" data-cy="afat">
              {{ formatDollars(rate.afat_fees) }}

            <td v-if="columns.has('mandatoryFees')" data-cy="mandatory">
              {{ formatDollars(rate.mandatory_fees) }}
            </td>
            <td data-cy="total">{{ formatDollars(total(rate)) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDollars } from "../utils"

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    rates: {
      required: true,
      type: Array,
    },
    mergeProgramFees: {
      required: true,
      type: Boolean,
    },
    mergeAllFees: {
      required: true,
      type: Boolean,
    },
    unitsGte: {
      required: true,
      type: Boolean,
    },
    term: {
      required: true,
      type: Number,
    }
  },
  computed: {
    columns() {
      const cols = new Set()
      cols.add("tuition")
      this.rates.forEach(rate => {
        const {
          college_fees,
          program_fees,
          mandatory_fees,
          afat_fees,
          differential_tuition,
        } = rate
        if (parseFloat(college_fees)) cols.add("collegeFees")
        if (parseFloat(program_fees)) cols.add("programFees")
        if (parseFloat(mandatory_fees)) cols.add("mandatoryFees")
        if (parseFloat(afat_fees)) cols.add("afatFees")
        if (parseFloat(differential_tuition)) cols.add("differentialTuition")
      })
      if (this.mergeProgramFees) {
        cols.delete("tuition")
        cols.delete("programFees")
        cols.delete("differentialTuition")
        cols.add("tuitionAndProgramFees")
      }

      if (this.mergeAllFees) {
        cols.delete("tuition")
        cols.delete("collegeFees")
        cols.delete("programFees")
        cols.delete("differentialTuition")
        cols.delete("mandatoryFees")
        cols.delete("afatFees")
      }
      return cols
    },
    orderedRates() {
      const rates = [...this.rates]
      rates.sort((a, b) => a.units - b.units)
      rates.forEach((rate, i) => {
        rate.unitString = rate.units.toString()
        if (this.unitsGte && i === rates.length - 1) {
          rate.unitString += "+"
        }
        rate.unitString += ` unit${rate.units === 1 ? "" : "s"}`
      })
      return rates
    },
    feesLink() {
      if (this.term >= 202407) {
        return "https://bursar.arizona.edu/tuition/fall-24-descriptions"
      } else {
      return "https://bursar.arizona.edu/tuition/fees/descriptions "
      }
    }
  },
  methods: {
    formatDollars,
    tuitionAndProgram(rate) {
      const centSum =
        this.toInt(rate.tuition) +
        this.toInt(rate.program_fees) +
        this.toInt(rate.differential_tuition)
      const dollarSum = centSum / 100
      return dollarSum.toFixed(2)
    },
    total(rate) {
      const values = [
        rate.tuition,
        rate.college_fees,
        rate.program_fees,
        rate.mandatory_fees,
        rate.differential_tuition,
        rate.afat_fees,
      ]
      const totalCents = values.reduce(
        (acc, value) => this.toInt(value) + acc,
        0,
      )
      return (totalCents / 100).toFixed(2)
    },
    toInt(value) {
      return parseFloat(value) * 100
    },
  },
}
</script>

<style lang="postcss" scoped>
th:first-child,
td:first-child {
  padding-left: 1rem;
}
.fixed-layout {
  width: 100%; /* Or any specific width */
  table-layout: fixed;
}
</style>
