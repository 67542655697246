<template>
  <ul class="list-group">
    <li
      v-for="({ id, selection, dropdown }, i) in finalSelections"
      :key="i"
      class="list-group-item selected-options d-flex justify-content-between rounded-0 border-left-0 border-right-0 px-3 py-1"
    >
      <span :id="id" class="option-label">{{ dropdown }}</span>
      <span :id="id + '-selection'" class="text-right">{{ selection }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    dropdowns: {
      required: true,
      type: Array,
    },
    selections: {
      required: true,
      type: Array,
    },
  },
  computed: {
    /** Join selections with dropdowns and their options */
    finalSelections() {
      return this.selections.map((selection, index) => {
        const dropdown = this.dropdowns[index]
        const option = dropdown.options.find(
          option => option.value === selection.value,
        )
        return {
          id: dropdown.id,
          dropdown: dropdown.label,
          selection: option.label,
        }
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
.option-label {
  font-weight: 500;
}
</style>
